function worksSlider() {
  $(".works__slider").slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    center: true,
    autoplay: false,
    arrows: true,
    infinite:false,
    prevArrow: $(".work-prev"),
    nextArrow: $(".work-next"),
  });
}
