function team() {
  gsap.set(".team__overlay", { y: "-10%", opacity: 0 });
  ScrollTrigger.batch(".team__overlay", {
    onEnter: (batch) => gsap.to(batch, { y: "100%", opacity: 1 }),
    onLeaveBack: (batch) => gsap.to(batch, { y: "-100%" }),
    start: "top 70%",
    end: "bottom",
  });

  gsap.set(".team__item img", { opacity: 0, y: -24 });
  ScrollTrigger.batch(".team__item img", {
    onEnter: (batch) => gsap.to(batch, { opacity: 1, y: 0 }),
    onLeaveBack: (batch) => gsap.to(batch, { opacity: 0, y: 24 }),
    start: "top 68%",
    end: "bottom",
    
  });

  gsap.set(".team__details", { opacity: 0, y: -50 });
  ScrollTrigger.batch(".team__details", {
    onEnter: (batch) => gsap.to(batch, { opacity: 1, y: 0 }),
    onLeaveBack: (batch) => gsap.to(batch, { opacity: 0, y: 24 }),
    start: "top 68%",
    end: "bottom",
  });

  gsap.set(".team__designation", { opacity: 0,});
  ScrollTrigger.batch(".team__designation", {
    onEnter: (batch) => gsap.to(batch, { opacity: 1, }),
    onLeaveBack: (batch) => gsap.to(batch, { opacity: 0, }),
    start: "top 68%",
    end: "bottom",
  });


}
