(function ($) {
  $(document).ready(function () {
    navigation();
    bannerSlider();
    services();
    worksSlider();
    clientsSlider();
    clientsUsersSlider();
    leadingValues();
    team();
    creative();
    careers();
    AOS.init();
  });
})(jQuery);
