function careers() {
  var swiper = new Swiper(".careersSwiper", {
    direction: "horizontal",
    slidesPerView: 1,
    centeredSlides: false,
    mousewheel: true,
    pagination: {
      el: ".swiper-pagination",
      type: "fraction",
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    breakpoints: {
      1025: {
        slidesPerView: 4,
        centeredSlides: true,
      },
      990: {
        slidesPerView: 3,
        centeredSlides: true,
      },
      767: {
        slidesPerView: 2,
        centeredSlides: true,
      },
    },
  });

  $('.experience__nav > li ').on('click', function() {
    $(this).parent().toggleClass('-is-open');
  });

}
