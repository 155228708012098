function creative() {

  if ($(window).width() > 767) {
    $('.creative__item a').click(function(){
        $('.creative__item a').removeClass('active')
        $(this).addClass('active')
        $('html, body').animate({
            scrollTop: $( $(this).attr('href') ).offset().top - 110
        }, 500);
        return false;
    });

    
    gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
    var buttons = gsap.utils.toArray(".creative__item a");
    const panels = gsap.utils.toArray(".creative__contents");

  panels.forEach((panel, i) => {
    ScrollTrigger.create({
      trigger: panel,
      start: "top 50%",
    });
  });


  document.querySelectorAll(".creative__contents").forEach((item) => {
    gsap.to(item, {
      scrollTrigger: {
        trigger: item,
        start: "top bottom",
        end: "top",
        scrub: true,
        ease: "elastic.out(1, 0.5)",
      },
      duration: 1,
      opacity: 1,
    });
    // gsap.to(item, {
    //   scrollTrigger: {
    //     trigger: item,
    //     start: "top 6%",
    //     end: "bottom",
    //     scrub: true,
    //     ease: "elastic.out(1, 0.5)",
        
    //   },
    //   duration: 1,
    //   opacity: 0,
    // });
  });


  // Get all sections that have an ID defined
const sections = document.querySelectorAll(".creative__contents[id]");

// Add an event listener listening for scroll
window.addEventListener("scroll", navHighlighter);

function navHighlighter() {
  
  // Get current scroll position
  let scrollY = window.pageYOffset;
  
  // Now we loop through sections to get height, top and ID values for each
  sections.forEach(current => {
    const sectionHeight = current.offsetHeight;
    const sectionTop = current.offsetTop - 50;
    sectionId = current.getAttribute("id");
    
    /*
    - If our current scroll position enters the space where current section on screen is, add .active class to corresponding navigation link, else remove it
    - To know which link needs an active class, we use sectionId variable we are getting while looping through sections as an selector
    */
    if (
      scrollY > sectionTop &&
      scrollY <= sectionTop + sectionHeight
    ){
      document.querySelector(".creative__item a[href*=" + sectionId + "]").classList.add("active");
    } else {
      document.querySelector(".creative__item a[href*=" + sectionId + "]").classList.remove("active");
    }
  });
}


}
  
}
