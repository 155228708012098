function bannerSlider() {
  // $('.banner__slider').slick({
  //     infinite: true,
  //     slidesToShow: 1,
  //     slidesToScroll: 1,
  //     dots: true,
  //     center: true,
  //     autoplay: true,
  //     arrows: false,
  // });

  var swiper2 = new Swiper(".mySwiper", {
    speed: 2000,
    loop: true,
    parallax: true,
    autoplay: {
      delay: 3000,
    },
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },

  });
}
