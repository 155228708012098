window.addEventListener('scroll', function(){
   // for Header Sticky
$(window).scroll(function() {
    if ($(this).scrollTop() > 250){  
        $('header').addClass("header__fixed");
    }
    else{
        $('header').removeClass("header__fixed");
    }
});



});