function clientsSlider() {
    $('.clients__slider').slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        center: true,
        autoplay: false,
        arrows: false,
    }); 
}

function clientsUsersSlider() {

    $('.clients__content').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        asNavFor: '.clients__nav',
        draggable: false,
        infinite: true,
    });
    $('.clients__nav').slick({
        slidesToShow: 2,
        slidesToScroll: 1,
        asNavFor: '.clients__content',
        dots: false,
        focusOnSelect: false,
        infinite: true,
        draggable: false,
        
    });
}