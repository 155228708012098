function navigation() {
    $('#toggle').click(function() {
        $(this).toggleClass('active');
        $('header').toggleClass('open');
       });
       const activeId = $('.header__nav li.active a').attr('data-related'); 

       $(".header__submenu").each(function(){
        $(this).hide();
       if($(this).attr('id') == $('.header__nav li.active a').attr('data-related')) {
           $(this).show();
       }
   });
   $('.header__nav li').on( "click", function(e) {
    $('.header__nav li').removeClass('active');
    $(this).addClass('active');
   });
   
   $('.header__nav li a').on( "click", function(e) {
    //    e.preventDefault();
       var id = $(this).attr('data-related'); 
       $(".header__submenu").each(function(){
           $(this).hide();
           if($(this).attr('id') == id) {
               $(this).show();
           }
       });
   });

   $('.backbtn').on( "click", function(e) {
    $(".header__submenu").each(function(){
        $(this).hide();})
   });
   

   
   
}