function leadingValues() {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

  const navLinks = gsap.utils.toArray(".values__nav li a");

  navLinks.forEach((btn, index) => {
    btn.addEventListener("click", () => {
      gsap.to(window, {
        duration: 1,
        scrollTo: { y: "#values0" + (index + 1), offsetY: 250 },
      });
    });
  });

  const headerHeight = $(".header").height();
  ScrollTrigger.create({
    trigger: ".values__nav",
    start: "0 " + headerHeight,
    endTrigger: ".values__content",
    end: "bottom 50%",
    pin: true,
    pinSpacing: false,
    scrub: 1,
    toggleClass: { className: "fixed", targets: ".values__nav", zIndex: 5 },
  });

  const panels = gsap.utils.toArray(".values__item");
  var buttons = gsap.utils.toArray(".values__nav li");

  panels.forEach((panel, i) => {
    ScrollTrigger.create({
      trigger: panel,
      start: "top 60%",
      end: "bottom",
      onEnter: () => {
        gsap.set(buttons[i], { className: "+=item-active active" });
      },
      onLeaveBack: () => {
        gsap.set(buttons[i], { className: "" });
      },
    });
  });

  gsap.set(".values__details", { opacity: 0, y: -50 });
  ScrollTrigger.batch(".values__details", {
    onEnter: (batch) => gsap.to(batch, { opacity: 1, y: 0 }),
    onLeaveBack: (batch) => gsap.to(batch, { opacity: 0, y: 50 }),
    start: "top 60%",
    end: "bottom",
  });
  

  gsap.set(".values__image", { opacity: 0, y: -50, x: 50 });
  ScrollTrigger.batch(".values__image", {
    onEnter: (batch) => gsap.to(batch, { opacity: 1, y: 0, x: 0 }),
    onLeaveBack: (batch) => gsap.to(batch, { opacity: 0, y: 50, x: 50 }),
    start: "top 60%",
    end: "bottom",
  });
}
