function services() {
  $(".services__contents").slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: ".services__nav",
  });
  $(".services__nav-main").slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    asNavFor: ".services__contents",
    dots: false,
    focusOnSelect: true,
    vertical: true,
    infinite: false,
    centerMode: true,
    centerPadding: 0,
    prevArrow:
      '<button class="slick-prev slick-arrow"><i class="icon-icn_uparrow"></i></button>',
    nextArrow:
      '<button class="slick-next slick-arrow"><i class="icon-icn_downarrow"></i></button>',
  });

  $(".services__nav-slider").slick({
    slidesToShow: 5,
    slidesToScroll: 1,
    dots: false,
    focusOnSelect: true,
    vertical: true,
    infinite: false,
    centerMode: true,
    centerPadding: 0,
    prevArrow:
      '<button class="slick-prev slick-arrow"><i class="icon-icn_uparrow"></i></button>',
    nextArrow:
      '<button class="slick-next slick-arrow"><i class="icon-icn_downarrow"></i></button>',
  });

  $(".technology__nav > li").on("click", function () {
    $(this).parent().toggleClass("-is-open");
  });
}
